<!--右下角兴趣点和相机列表-->
<template>
    <div v-if="showPanel">
        <panel style="background: rgba(21, 23, 97, 0.85)">

            <div class="panel-title">
                {{title}}
            </div>
            <div class="interest-camera-list-wrapper">
                <template v-if="type === 'point'">
                    <div class="list-item" v-for="item of pointData" :key="item.id" @click="handlePointClick(item)">
                        <div class="list-item-image" :style="{backgroundColor: IMPORTANT_ROAD_SECTION[item.type].style.baseColor}">
                            <img :src="IMPORTANT_ROAD_SECTION[item.type].imgSelect">
                        </div>
                        <div class="list-item-text">{{item.poiName}}</div>
                    </div>
                </template>
                <template v-else-if="type === 'camera'">
                    <div class="list-item" v-for="item of cameraData" :key="item.id" @click="handleCameraClick(item)">
                        <div class="list-item-image">
                            <img style="height: 24px; width: 24px;" src="../../assets/images/importantRoad/icon_list_sxj.png">
                        </div>
                        <div class="list-item-text" :title="item.viewCamera.cameraName">{{item.viewCamera.cameraName}}</div>
                    </div>
                </template>
            </div>
        </panel>
    </div>
</template>

<script>

    import {IMPORTANT_ROAD_SECTION} from '../../core/enums'
    import Panel from '../../components/common/Panel'
    import {getAssignedCamera} from '../../api/roadSectionPoi'

    export default {
        name: "InterestCameraList",
        components: {
            Panel
        },
        data() {
            return {
                IMPORTANT_ROAD_SECTION,
                showPanel: false,
                title: '', // 标题
                type: 'point', // 面板类型(兴趣点point, 相机camera)
                pointData: [], // 兴趣点数据
                cameraData: [] // 摄像机数据
            }
        },
        methods: {
            refreshData (roadSectionData, roadData) { // 根据已选择的路段刷新数据
                this.createData(roadSectionData, roadData)
                this.showPanel = true
            },
            createData (roadSectionData, roadData) {
                this.title = roadData.roadSectionName + '兴趣点信息'
                this.type = 'point'
                let pointData = []
                for (let key in roadSectionData) {
                    if (roadSectionData[key].selected) { // 选中了
                        if (roadData[key] !== null && roadData[key].length > 0) {
                            // 添加
                            roadData[key].forEach(v => {
                                v.type = key
                                v.roadSectionCode = roadData.roadSectionCode
                            })
                            pointData.push(...roadData[key])
                        }
                    }
                }
                this.pointData = pointData
            },
            resetInterestCameraList () { // 重置列表
                this.pointData = []
                this.cameraData = []
                this.showPanel = false
            },
            selectedInterestPoint (data) { // 选中某个兴趣点
                this.showPanel = true
                this.type = 'camera'
                let title = data.poiName + '摄像机列表'
                if (data.poiName.length > 16) {
                    title = data.poiName.substring(0, 14) + '...摄像机列表'
                }
                this.title = title
                this.getCameraData(data.id)
            },
            handlePointClick (data) { // 点击兴趣点
                this.selectedInterestPoint(data)
                this.$emit('clickInterestPoint', data)
            },
            handleCameraClick (data) { // 点击相机
                this.$emit('playVideo', data.viewCamera)
            },
            getCameraData (id) { // 获取相机数据
                getAssignedCamera(id).then(res => {
                    this.cameraData = res.data
                })
            }
        },
        mounted() {
            this.$nextTick(() => {

            })
        }
    }
</script>

<style lang="less" scoped>

    .panel-title {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 800;
        color: #0088FF;
        margin-left: 16px;
        padding-top: 16px;
    }

    .interest-camera-list-wrapper {
        overflow-y: auto;
        height: 90%;

        .list-item {
            position: relative;
            height: 40px;
            padding: 0 16px;
            cursor: pointer;
            &:nth-of-type(1) {
                margin-top: 8px;
            }
            &:hover {
                filter: brightness(1.2);
            }
            &:after {
                content: '';
                position: absolute;
                left: 16px;
                right: 16px;
                top: 40px;
                height: 1px;
                background-color: #0088FF;
            }

            .list-item-image {

                img {
                    width: 20px;
                    height: 20px;
                }

                width: 24px;
                height: 24px;
                padding-left: 2px;
                margin-top: 8px;
                float: left;
            }

            .list-item-text {
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: bold;
                line-height: 40px;
                color: rgba(255, 255, 255, 0.85);
                margin-left: 12px;
                float: left;
                white-space: nowrap;
                overflow: hidden;
                width: 288px;
                text-overflow: ellipsis;
            }
        }
    }

</style>
