import { get } from '@/utils/request'
const URL = '/manage/roadSectionPoi/'

// 查询各类型兴趣点数量
export const getTags = () => get(URL + '/tags/all')

// 查询全部路段的数据
export const getRoadPoi = () => get(URL + '/tags/roadPoi')

// 根据路段兴趣点查找相机:
export const getAssignedCamera = id => get(URL + '/getAssignedCamera/' + id)
