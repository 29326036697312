<!--重点路段点位分析主页-->
<template>
    <div :style="{height:bodyHeight+'px'}" class="important-road-wrapper">

        <!--标题-->
        <div class="important-road-title">
            <img src="../../assets/images/importantRoad/important-road-title.gif">
        </div>

        <!--返回按钮-->
        <img class="btn-back" @click="backClick">

        <important-road-select v-if="roadSectionCountData !== null && roadSectionPoiData !== null"
                               class="important-road-select" :roadSectionCountData="roadSectionCountData"
                               @roadTypeSelectedChange="roadTypeSelectedChange"/>
        <road-list v-if="roadSectionCountData !== null && roadSectionPoiData !== null" class="road-list" ref="roadList"
                   @roadClick="roadClick"/>
        <road-map v-if="roadSectionCountData !== null && roadSectionPoiData !== null" class="road-map" ref="roadMap"
                  @clickInterestPoint="clickMapInterestPoint" @playVideo="playVideo"/>
        <interest-point-chart class="interest-point-chart" ref="interestPointChart"/>
        <interest-camera-list class="interest-camera-list" ref="interestCameraList"
                              @clickInterestPoint="clickListInterestPoint" @playVideo="playVideo"/>

        <monitor-camera-video-play ref="monitorCameraVideoPlay"
                                   :select-play-camera="selectPlayCamera"
                                   :button-visible="buttonVisible"
                                   v-if="isShowCameraVideo"
                                   @cameraPlayClose="cameraVideoCloseClick"
                                   @cameraVideoCloseButFourScreenClick="cameraVideoCloseButFourScreenClick"/>
        <four-screen ref="fourScreen" @oneScreen="oneScreen" @onClose="()=>{this.fromFour = false}"/>

    </div>
</template>

<script>

    import {getExistNavigatorBodyHeignt} from '../../core/bodyHeigntUtil'
    import {getTags, getRoadPoi} from '../../api/roadSectionPoi'
    import Panel from '../../components/common/Panel'
    import ImportantRoadSelect from '../../components/importantRoad/ImportantRoadSelect'
    import RoadList from '../../components/importantRoad/RoadList'
    import RoadMap from '../../components/importantRoad/RoadMap'
    import InterestPointChart from '../../components/importantRoad/InterestPointChart'
    import InterestCameraList from '../../components/importantRoad/InterestCameraList'
    import MonitorCameraVideoPlay from "../../components/monitor/MonitorCameraVideoPlay"
    import FourScreen from "../../components/monitor/monitorCameraVideoPlay/FourScreen"


    export default {
        name: "ImportantRoad",
        components: {
            ImportantRoadSelect,
            RoadList,
            RoadMap,
            InterestPointChart,
            InterestCameraList,
            MonitorCameraVideoPlay,
            FourScreen,
            Panel
        },
        data() {
            return {
                bodyHeight: "",
                buttonVisible: {
                    nineScreen: false
                },
                roadSectionData: null, // 路段选中情况
                roadSectionCountData: null, // 顶部重点路段点选择的数据
                roadSectionPoiData: null, // 左侧列表数据
                selectPlayCamera: {}, // 当前选中的相机
                isShowCameraVideo: false,
                fromFour: false // 是否是从四分屏切回来的
            }
        },
        methods: {
            getTagsData() {
                getTags().then(res => {
                    this.roadSectionCountData = res.data
                    this.$refs.interestPointChart.refreshData(this.roadSectionCountData)
                })
            },
            getRoadPoiData() {
                getRoadPoi().then(res => {
                    this.roadSectionPoiData = res.data
                })
            },
            roadClick(roadData) { // 在路段列表点击某个路段
                this.$refs.roadMap.highlightPoint(roadData)
                this.$refs.interestCameraList.refreshData(this.roadSectionData, roadData)
            },
            backClick() { // 点击返回按钮
                this.$refs.roadMap.resetMap()
                this.$refs.roadList.resetRoadList()
                this.$refs.interestCameraList.resetInterestCameraList()
            },
            roadTypeSelectedChange(roadSectionData) { // 选中/取消选中路段
                // 计算当前可见的数据
                this.roadSectionData = roadSectionData
                let roadSectionPoiVisibleData = this.roadSectionPoiData.filter(
                    item => (roadSectionData.iceProneSection.selected && item.iceProneSection) ||
                        (roadSectionData.longDownhillSection.selected && item.longDownhillSection) ||
                        (roadSectionData.congestionSection.selected && item.congestionSection) ||
                        (roadSectionData.trafficAccidentSection.selected && item.trafficAccidentSection) ||
                        (roadSectionData.lowVisibilitySection.selected && item.lowVisibilitySection) ||
                        (roadSectionData.mountainSlideSection.selected && item.mountainSlideSection) ||
                        (roadSectionData.bigBridgeSection.selected && item.bigBridgeSection)
                )
                this.$refs.roadList.refreshData(roadSectionData, roadSectionPoiVisibleData)
                this.$refs.roadMap.refreshData(roadSectionData, roadSectionPoiVisibleData)
                this.$refs.interestCameraList.resetInterestCameraList()
            },
            clickMapInterestPoint(data) { // 点击地图上的兴趣点
                // 影响到列表-加载相机列表
                this.$refs.interestCameraList.selectedInterestPoint(data)
            },
            clickListInterestPoint(data) { // 点击列表上的兴趣点
                // 影响到地图-加载相机Marker
                this.$refs.roadMap.getCameraData(data.id)
            },
            playVideo(camera) { // 弹出视频播放框,播放视频
                if (this.$store.fourScreen) { // 四分屏状态,向四分屏中添加视频
                    this.$refs.fourScreen.addVideo(camera)
                } else { // 普通地图状态,打开视频播放窗口
                    this.selectPlayCamera = camera
                    this.isShowCameraVideo = true
                    this.$refs.roadMap.showCameraTooltip(camera) // 显示相机名称
                    this.$refs.roadMap.moveToCamera(camera) // 视角移动到相机
                    // 播放视频
                    this.$nextTick(() => {
                        this.$refs.monitorCameraVideoPlay.play(camera)
                    })
                }
            },
            oneScreen(camera) { // 切回一分屏
                this.selectPlayCamera = camera
                this.isShowCameraVideo = true
                this.fromFour = true // 是从四分屏切换回来的
                // 播放视频
                this.$nextTick(() => {
                    this.$refs.monitorCameraVideoPlay.play(camera) // 点击相机列表,直接跳出视频播放窗口
                })
            },
            cameraVideoCloseClick() { // 关闭视频播放窗口(同时关闭四分屏)
                this.isShowCameraVideo = false
                this.$refs.fourScreen.close() // 关闭隐藏的四分屏窗口
            },
            cameraVideoCloseButFourScreenClick() { // 关闭视频播放窗口(不关闭四分屏)
                this.isShowCameraVideo = false
            }
        },
        beforeMount: function () {
            this.bodyHeight = getExistNavigatorBodyHeignt();
        },
        mounted: function () {
            let that = this;
            that.$nextTick(() => {
                this.getTagsData()
                this.getRoadPoiData()

                window.onresize = () => {
                    return (() => {
                        that.bodyHeight = getExistNavigatorBodyHeignt();
                    })()
                }
            })

            this.$eventBus.$on('fourScreen', (cameraData) => {
                if (this.fromFour) { // 是从4分屏切回来的, 点击4分屏,直接打开原先的4分屏面板
                    this.$refs.fourScreen.showWindow()
                } else { // 不是从四分屏切回来的,打开新的4分屏面板
                    this.$refs.fourScreen.open(cameraData)
                }
            })
        }
    }
</script>

<style lang="less" scoped>

    .important-road-title {
        position: absolute;
        text-align: center;
        z-index: 8;
        top: 90px;
        width: 100%;
        pointer-events: none;
    }

    .important-road-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #080B45;
        overflow-y: auto;

        .btn-back {
            position: absolute;
            top: 90px;
            left: 392px;
            width: 60px;
            height: 60px;
            z-index: 9;
            cursor: pointer;
            content: url("../../assets/images/importantRoad/btn_back_normal.png");

            &:hover {
                content: url("../../assets/images/importantRoad/btn_back_hover.png");
            }
        }

        .important-road-select {
            margin-top: 12px;
        }

        .road-list {
            z-index: 9;
            position: absolute;
            margin-top: 16px;
            margin-left: 16px;
            width: 360px;
            height: 904px;
        }

        .road-map {
            position: absolute;
            width: 100%;
            height: 904px;
        }

        .interest-point-chart {
            z-index: 9;
            position: absolute;
            right: 16px;
            margin-top: 16px;
            width: 360px;
            height: 298px;
        }

        .interest-camera-list {
            z-index: 9;
            position: absolute;
            right: 16px;
            margin-top: 330px;
            width: 360px;
            height: 590px;
        }
    }

</style>
