<!--页面左侧路段列表-->
<template>
    <div>
        <panel style="background: rgba(21, 23, 97, 0.85)">
            <div class="interest-point">
                <span class="interest-point-title">已选类型的兴趣点数量：</span>
                <span class="interest-point-count animated" :class="{flipInX: animatedFlag}">{{totalSelected}}</span>
            </div>
            <!--分页组件-->
            <div class="pagination">
                <div class="page" :class="{'page-active': currentPage === pageNum}" ref="pages" v-for="pageNum in Math.ceil(roadSectionPoiData.length / 12)" :key="pageNum" @click="changePage(pageNum)"></div>
            </div>
            <!--路段列表-->
            <div class="road-list">
                <div class="road-item" ref="roadItems" v-for="(item, index) of roadSectionPoiPageData"
                     :key="index" @click.stop="handleClick($event, item, index)">
                    <div class="road-item-title">{{item.roadSectionName}}</div>
                    <div class="road-item-img">
                        <div class="icon-wrapper" v-if="roadSectionData.iceProneSection.selected && item.iceProneSection">
                            <img :src="IMPORTANT_ROAD_SECTION.iceProneSection.imgList">
                            <span class="road-item-count">{{item.iceProneSection.length}}</span>
                        </div>
                        <div class="icon-wrapper" v-if="roadSectionData.longDownhillSection.selected && item.longDownhillSection">
                            <img :src="IMPORTANT_ROAD_SECTION.longDownhillSection.imgList">
                            <span class="road-item-count">{{item.longDownhillSection.length}}</span>
                        </div>
                        <div class="icon-wrapper" v-if="roadSectionData.congestionSection.selected && item.congestionSection">
                            <img :src="IMPORTANT_ROAD_SECTION.congestionSection.imgList">
                            <span class="road-item-count">{{item.congestionSection.length}}</span>
                        </div>
                        <div class="icon-wrapper" v-if="roadSectionData.trafficAccidentSection.selected && item.trafficAccidentSection">
                            <img :src="IMPORTANT_ROAD_SECTION.trafficAccidentSection.imgList">
                            <span class="road-item-count">{{item.trafficAccidentSection.length}}</span>
                        </div>
                        <div class="icon-wrapper" v-if="roadSectionData.lowVisibilitySection.selected && item.lowVisibilitySection">
                            <img :src="IMPORTANT_ROAD_SECTION.lowVisibilitySection.imgList">
                            <span class="road-item-count">{{item.lowVisibilitySection.length}}</span>
                        </div>
                        <div class="icon-wrapper" v-if="roadSectionData.mountainSlideSection.selected && item.mountainSlideSection">
                            <img :src="IMPORTANT_ROAD_SECTION.mountainSlideSection.imgList">
                            <span class="road-item-count">{{item.mountainSlideSection.length}}</span>
                        </div>
                        <div class="icon-wrapper" v-if="roadSectionData.bigBridgeSection.selected && item.bigBridgeSection">
                            <img :src="IMPORTANT_ROAD_SECTION.bigBridgeSection.imgList">
                            <span class="road-item-count">{{item.bigBridgeSection.length}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </panel>
    </div>
</template>

<script>

    import { deepClone } from '../../utils/util'
    import {IMPORTANT_ROAD_SECTION} from '../../core/enums'
    import Panel from '../../components/common/Panel'

    export default {
        name: "ImportantRoadSelect",
        components: {
            Panel
        },
        data() {
            return {
                IMPORTANT_ROAD_SECTION,
                animatedFlag: true,
                selectedFlag: { // 记录当前选中的记录是第几页的第几条,用于切换页码之后再切回来的回显
                    page: 0,
                    index: 0
                },
                roadSectionData: null, // 顶部选择状态数据
                roadSectionPoiData: [], // 选择状态的全部路段数据
                roadSectionPoiPageData: null, // 分页显示的当前数据
                currentPage: 1, // 当前第几页,从1开始
                totalSelected: 0 // 选中总数
            }
        },
        methods: {
            refreshData (roadSectionData, roadSectionPoiData) { // 根据已选择的路段类型刷新数据
                this.roadSectionPoiData = roadSectionPoiData
                this.roadSectionData = roadSectionData
                this.currentPage = 1
                this.calcTotalCount()
                this.resetRoadList()
                this.calcPageData()
            },
            changePage (pageNum) { // 页码切换
                if (this.currentPage === pageNum) { // 根本没换页
                    return false
                }
                this.currentPage = pageNum
                this.calcPageData()
                this.$nextTick(() => {
                    // 先清空全部选中效果
                    this.$refs.roadItems.forEach(item => {
                        item.classList.remove('road-item-active')
                    })
                    if (pageNum === this.selectedFlag.page) { // 切换的页码是选中数据的页码,重新设置选中状态
                        this.$refs.roadItems[this.selectedFlag.index].classList.add('road-item-active')
                    }
                })
            },
            calcTotalCount () { // 计算已选择总数
                this.animatedFlag = false
                setTimeout(() => {
                    this.animatedFlag = true
                    let count = 0
                    for (let i in this.roadSectionData) {
                        if (this.roadSectionData[i].selected) {
                            count += this.roadSectionData[i].count
                        }
                    }
                    this.totalSelected = count
                }, 50)
            },
            calcPageData () { // 计算分页数据
                this.roadSectionPoiPageData = deepClone(this.roadSectionPoiData).splice((this.currentPage - 1) * 12, this.currentPage * 12)
            },
            resetRoadList () {
                this.currentPage = 1
                this.calcPageData()
                this.selectedFlag.page = 0
                this.selectedFlag.index = 0
                if (this.$refs.roadItems !== undefined) {
                    // 清空列表选中状态
                    this.$refs.roadItems.forEach(item => {
                        item.classList.remove('road-item-active')
                    })
                }
            },
            handleClick (e, data, index) {
                this.selectedFlag.page = this.currentPage
                this.selectedFlag.index = index
                this.$refs.roadItems.forEach(item => {
                    item.classList.remove('road-item-active')
                })
                e.target.classList.add('road-item-active')
                this.$emit('roadClick', data)
            }
        },
        mounted() {
            this.$nextTick(() => {

            })
        }
    }
</script>

<style lang="less" scoped>

    .interest-point {
        color: #0088FF;
        padding-top: 20px;
        padding-left: 16px;
        .interest-point-title {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 800;
        }
        .interest-point-count {
            float: right;
            line-height: 24px;
            margin-right: 16px;
            font-size: 28px;
            font-weight: 400;
            font-family: lcd;
        }
    }

    .pagination {
        position: relative;
        height: 32px;
        margin-top: 14px;
        line-height: 32px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .page {
            cursor: pointer;
            float: left;
            margin-left: 6px;
            width: 12px;
            height: 12px;
            background: #0088FF;
            opacity: 0.65;
            border-radius: 12px;
            transition: width 0.5s;
        }

        .page-active {
            width: 40px;
            opacity: 1;
        }
    }

    .road-list {
        .road-item {
            height: 64px;
            line-height: 64px;
            padding-left: 16px;
            cursor: pointer;
            border: 1px solid transparent;

            &:hover {
                filter: brightness(1.2);
            }

            .road-item-title {
                float: left;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 400;
                color: rgba(255, 255, 255, 0.85);
                pointer-events: none;
            }

            .road-item-img {
                margin-left: 32px;
                position: relative;
                text-align: center;
                float: left;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #FFFFFF;
                pointer-events: none;

                .icon-wrapper {
                    float: left;
                    position: relative;
                    margin-left: 4px;
                }

                .road-item-count {
                    margin-top: 2px;
                    position: absolute;
                    left: 0px;
                    right: 0px;
                }
            }
        }

        .road-item-active {
            background: rgba(0, 136, 255, 0.3);
            border: 1px solid #0088FF;
            border-radius: 4px;
        }
    }

</style>
