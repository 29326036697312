<!--四角边框面板-->
<template>
  <div class="panel-wrapper">

    <!--四个边角-->
    <div class="panel-corner">
      <img :src="Corner.BorderLeftTop">
      <img :src="Corner.BorderRightTop">
      <img :src="Corner.BorderLeftBottom">
      <img :src="Corner.BorderRightBottom">
    </div>

    <!--页面内容-->
    <slot></slot>

  </div>
</template>

<script>

  import Corner from '../../assets/images/corner'

export default {
  name: 'Panel',
  data () {
    return {
      Corner, // 四个边角
    }
  }

}
</script>

<style lang="less" scoped>

  .panel-wrapper {
    background: #0A0E4B;
    width: 100%;
    height: 100%;
  }

  .panel-corner {
    & img:nth-child(1) {
      position: absolute;
      top: -2px;
      left: -2px;
    }

    & img:nth-child(2) {
      position: absolute;
      right: -2px;
      top: -2px;
    }

    & img:nth-child(3) {
      position: absolute;
      bottom: -2px;
      left: -2px;
    }

    & img:nth-child(4) {
      position: absolute;
      right: -2px;
      bottom: -2px;
    }
  }

</style>
