<!--页面顶部重点路段选择-->
<template>
    <div>
        <div class="road-select">
            <div class="road-option"
                 v-for="(item, key) in roadSectionData"
                 :key="key"
                 :style="{'border-color': IMPORTANT_ROAD_SECTION[key].style.baseColor, 'background': item.selected ? IMPORTANT_ROAD_SECTION[key].style.background : 'transparent'}"
                 @click="handleClick(item)"
            >
                <img class="option-img" :src="IMPORTANT_ROAD_SECTION[key].imgSelect"/>
                <span class="option-title">{{IMPORTANT_ROAD_SECTION[key].name}}</span>
                <span class="option-count">{{item.count}}</span>
            </div>
        </div>
    </div>
</template>

<script>

    import {IMPORTANT_ROAD_SECTION} from '../../core/enums'

    export default {
        name: "ImportantRoadSelect",
        props: {
            roadSectionCountData: {
                type: Object,
                default: () => {
                }
            }
        },
        data() {
            return {
                IMPORTANT_ROAD_SECTION,
                roadSectionData: null,
            }
        },
        methods: {
            initRoadSectionData() { // 初始化路段数据
                let temp = {}
                Object.keys(IMPORTANT_ROAD_SECTION).forEach(key => {
                    temp[key] = {
                        count: this.roadSectionCountData[key],
                        selected: true,
                    }
                })
                this.roadSectionData = temp
                this.$emit('roadTypeSelectedChange', this.roadSectionData)
            },
            handleClick(data) { // 点击路段
                data.selected = !data.selected
                this.$emit('roadTypeSelectedChange', this.roadSectionData)
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.initRoadSectionData()
            })
        }
    }
</script>

<style lang="less" scoped>

    .road-select {
        display: flex;
        padding-right: 16px;

        .road-option {
            cursor: pointer;
            line-height: 58px;
            margin-left: 16px;
            padding-left: 16px;
            padding-right: 16px;
            height: 64px;
            width: 14%;
            border: 2px solid;
            border-radius: 8px;
            overflow: hidden;
            white-space: nowrap;

            .option-img {
                margin-bottom: 4px;
            }

            .option-title {
                margin-left: 8px;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 800;
                color: #FFFFFF;
            }

            .option-count {
                float: right;
                font-family: lcd;
                font-weight: 400;
                font-size: 28px;
                color: #FFFFFF;
            }
        }
    }


</style>
