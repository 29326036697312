<!--路段地图-->
<template>
    <div class="map-wrapper">
        <div id="container" style="width: 100%;height: 100%; z-index: 1;"></div>
    </div>
</template>

<script>

    import Vue from 'vue/dist/vue.js'
    import {getAssignedCamera} from '../../api/roadSectionPoi'
    import {HEAT_MAP_ROAD_SIGNS} from "../../assets/images/map/roadSign"
    import ampUtil from '../../utils/ampUtil'
    import Panel from '../../components/common/Panel'

    let map

    export default {
        name: "RoadMap",
        components: {Panel},
        data() {
            return {
                mapCenter: [101.434697, 38.429996],
                infoWindow: null, // 相机名称框
                dialogStyle: { // 对话框颜色
                    '--dialog-color': '#969697'
                },
                roadSectionData: null, // 顶部选择状态数据
                roadSectionPoiData: [], // 选择状态的全部路段数据
                interestData: [], // 地图兴趣点数据
                markerGroupInterest: [], // 兴趣点marker组
                markerGroupCamera: [], // 相机marker组
                cityData: [ // 查询显示天气用到的城市数据: 酒泉市、嘉峪关市、张掖市、金昌市、武威市、兰州市、白银市、定西市、平凉市、天水市、庆阳市、陇南市、临夏回族自治州, 甘南藏族自治州
                    {
                        name: '兰州市',
                        position: [103.823305441,36.064225525]
                    },
                    {
                        name: '酒泉市',
                        // position: [98.5084145062,39.7414737682]
                        position: [96.110471,40.289548]
                    },
                    {
                        name: '嘉峪关市',
                        position: [98.2816345853,39.8023973267]
                    },
                    {
                        name: '张掖市',
                        position: [100.459891869,38.939320297]
                    },
                    {
                        name: '金昌市',
                        position: [102.208126263,38.5160717995]
                    },
                    {
                        name: '武威市',
                        position: [102.640147343,37.9331721429]
                    },
                    {
                        name: '白银市',
                        position: [104.171240904,36.5466817062]
                    },
                    {
                        name: '定西市',
                        position: [104.626637601,35.5860562418]
                    },
                    {
                        name: '平凉市',
                        position: [106.688911157,35.55011019]
                    },
                    {
                        name: '天水市',
                        position: [105.736931623,34.5843194189]
                    },
                    {
                        name: '庆阳市',
                        position: [107.644227087,35.7268007545]
                    },
                    {
                        name: '陇南市',
                        position: [104.934573406,33.3944799729]
                    },
                    {
                        name: '临夏回族自治州',
                        position: [103.215249178,35.5985143488]
                    },
                    {
                        name: '甘南藏族自治州',
                        position: [102.917442486,34.9922111784]
                    }
                ]
            }
        },
        methods: {
            mapCreate() {
                map = ampUtil.createRoadMap('container', this.mapCenter,
                    // 'amap://styles/b49663ed32df732c0cf580fce9d38749')
                    'amap://styles/914383f63364635eeaf00f796caf77a0')

                // 限制地图显示范围
                map.setLimitBounds(map.getBounds())
                // 设置鼠标样式
                map.setDefaultCursor('default')
                // 只显示甘肃省地图
                ampUtil.hideMapOtherAreasBySearch('甘肃省', map)

                //创建信息窗体
                this.infoWindow = new AMap.InfoWindow({
                    isCustom: true,
                    content:'',
                    offset: new AMap.Pixel(0, -40)
                })
            },
            createRoadSignMarker() { // 高速路指示牌
                let roadSignMarkers = []
                HEAT_MAP_ROAD_SIGNS.forEach(sign => {
                    let position = [sign.lng, sign.lat]
                    let iconOptions = {
                        size: new AMap.Size(32, 24),
                        imageSize: new AMap.Size(32, 24),
                        image: sign.src  // Icon的图像
                    }

                    let marker = new AMap.Marker({
                        position: position,
                        icon: new AMap.Icon(iconOptions),
                        zIndex: 8,
                        offset: new AMap.Pixel(-24, -18)
                    })

                    // map.add(marker)

                    roadSignMarkers.push(marker)
                    this.roadSignLayerGroup = ampUtil.createOverlayGroup(roadSignMarkers)
                    this.roadSignLayerGroup.setMap(map)
                    // this.roadSignLayerGroup.hide()
                })
            },
            refreshData (roadSectionData, roadSectionPoiData) { // 根据已选择的路段类型刷新数据
                this.roadSectionData = roadSectionData
                this.roadSectionPoiData = roadSectionPoiData
                this.resetMap()
                this.createInterestPointData()
            },
            resetMap () { // 重置地图
                // 清空对话框高亮状态
                let dialogs = document.getElementsByClassName('interest-point-dialog')
                dialogs.forEach(item => {
                    item.classList.remove(`dialog-${item.getAttribute('type')}-active`)
                })
                // 清空摄像机Marker
                if (this.markerGroupCamera.length > 0) {
                    map.remove(this.markerGroupCamera)
                    this.markerGroupCamera = []
                }
                // 恢复地图初始视角
                if (map !== undefined) {
                    map.panTo(this.mapCenter)
                    map.setZoom(6)
                }
                // 关闭信息窗体
                if (this.infoWindow !== null) {
                    this.infoWindow.close()
                }
            },
            highlightPoint (roadData) { // 高亮
                let dialogs = document.getElementsByClassName('interest-point-dialog')
                dialogs.forEach(item => {
                    item.classList.remove(`dialog-${item.getAttribute('type')}-active`)
                    if (item.getAttribute('roadSectionCode') === roadData.roadSectionCode) {
                        item.classList.add(`dialog-${item.getAttribute('type')}-active`)
                    }
                })
                this.fitView(roadData)
            },
            fitView (roadData) { // 自适应视野
                // 创建透明的marker进行视野自适应
                let fitMarkers = []
                this.interestData.forEach(item => {
                    if (item.roadSectionCode === roadData.roadSectionCode) {
                        let m = new AMap.Marker({
                            position: [item.longitude, item.latitude],
                            content: `<div></div>`,
                            map: map,
                            bubble:true //事件穿透到地图
                        })
                        fitMarkers.push(m)
                    }
                })
                map.setFitView(fitMarkers)
                map.remove(fitMarkers)
            },
            createInterestPointData () { // 创建兴趣点的数据列表
                let interestData = []
                this.roadSectionPoiData.forEach(item => {
                    if (this.roadSectionData.iceProneSection.selected && item.iceProneSection) { // 有这个点下的数据
                        item.iceProneSection.forEach(v => {
                            v.type = 'iceProneSection'
                            v.roadSectionCode = item.roadSectionCode
                        })
                        interestData.push(...item.iceProneSection)
                    }
                    if (this.roadSectionData.longDownhillSection.selected && item.longDownhillSection) { // 有这个点下的数据
                        item.longDownhillSection.forEach(v => {
                            v.type = 'longDownhillSection'
                            v.roadSectionCode = item.roadSectionCode
                        })
                        interestData.push(...item.longDownhillSection)
                    }
                    if (this.roadSectionData.congestionSection.selected && item.congestionSection) { // 有这个点下的数据
                        item.congestionSection.forEach(v => {
                            v.type = 'congestionSection'
                            v.roadSectionCode = item.roadSectionCode
                        })
                        interestData.push(...item.congestionSection)
                    }
                    if (this.roadSectionData.trafficAccidentSection.selected && item.trafficAccidentSection) { // 有这个点下的数据
                        item.trafficAccidentSection.forEach(v => {
                            v.type = 'trafficAccidentSection'
                            v.roadSectionCode = item.roadSectionCode
                        })
                        interestData.push(...item.trafficAccidentSection)
                    }
                    if (this.roadSectionData.lowVisibilitySection.selected && item.lowVisibilitySection) { // 有这个点下的数据
                        item.lowVisibilitySection.forEach(v => {
                            v.type = 'lowVisibilitySection'
                            v.roadSectionCode = item.roadSectionCode
                        })
                        interestData.push(...item.lowVisibilitySection)
                    }
                    if (this.roadSectionData.mountainSlideSection.selected && item.mountainSlideSection) { // 有这个点下的数据
                        item.mountainSlideSection.forEach(v => {
                            v.type = 'mountainSlideSection'
                            v.roadSectionCode = item.roadSectionCode
                        })
                        interestData.push(...item.mountainSlideSection)
                    }
                    if (this.roadSectionData.bigBridgeSection.selected && item.bigBridgeSection) { // 有这个点下的数据
                        item.bigBridgeSection.forEach(v => {
                            v.type = 'bigBridgeSection'
                            v.roadSectionCode = item.roadSectionCode
                        })
                        interestData.push(...item.bigBridgeSection)
                    }
                })
                this.interestData = interestData
                this.$nextTick(() => {
                    this.createInterestMarker()
                })
            },
            createInterestMarker() { // 创建兴趣点Marker
                if (this.markerGroupInterest.length > 0) {
                    map.remove(this.markerGroupInterest)
                    this.markerGroupInterest = []
                }
                this.interestData.forEach(data => {

                    // 创建点覆盖物
                    let marker = new AMap.Marker({
                        position: new AMap.LngLat(data.longitude, data.latitude),
                        content: `<div class="interest-point-dialog dialog-${data.type}" roadSectionCode="${data.roadSectionCode}" type="${data.type}">
                                <div class="image">
                                    <img>
                                </div>
                                <div class="text">${data.poiName}<span>（${data.poiCameraCount}）</span></div>
                            </div>`,
                        offset: new AMap.Pixel(-19, -46) // 根据对话框计算出的偏移量
                    })
                    this.markerGroupInterest.push(marker)
                    marker.on('click', () => {
                        this.$emit('clickInterestPoint', data)
                        this.getCameraData(data.id)
                    })
                    map.add(marker)

                    // 经纬度参照点(矩形左上角为原点)
                    // let marker1 = new AMap.Marker({
                    //     position: new AMap.LngLat(data.longitude, data.latitude),
                    //     content: `<div style="width: 10px; height: 10px; border: 1px solid red;"></div>`,
                    //     offset: new AMap.Pixel(0, 0)
                    // })
                    // map.add(marker1)
                })
            },
            createCameraMarker(cameraData) { // 创建相机Marker
                if (this.markerGroupCamera.length > 0) {
                    map.remove(this.markerGroupCamera)
                    this.markerGroupCamera = []
                }
                if (cameraData && cameraData.length > 0) {
                    cameraData.forEach(data => {
                        // 创建点覆盖物
                        let iconOptions = {
                            size: new AMap.Size(28, 40),
                            imageSize: new AMap.Size(28, 40),
                            image: require('../../assets/images/importantRoad/icon_map_sxj_online.png')
                        }
                        let position = ampUtil.splitlngLat(data.viewCamera.longAndLati)
                        let marker = new AMap.Marker({
                            position: position,
                            icon: new AMap.Icon(iconOptions),
                            offset: new AMap.Pixel(0, 6), // 根据对话框计算出的偏移量
                            anchor:'bottom-center', // 设置锚点方位
                        })
                        this.markerGroupCamera.push(marker)
                        marker.on('click', () => {
                            this.$emit('playVideo', data.viewCamera)
                        })
                        marker.on('mouseover', () => {
                            this.showCameraTooltip(data.viewCamera)
                        })
                        map.add(marker)
                    })
                    map.setFitView(this.markerGroupCamera)
                }
            },
            showCameraTooltip (camera) { // 显示相机气泡框
                let position = ampUtil.splitlngLat(camera.longAndLati) // 计算位置
                let MyComponent = Vue.extend({
                    template: `<div style="cursor: pointer; background: rgba(21, 23, 97, 0.85); border: 1px solid #0088FF; padding: 8px 12px; font-size: 12px; font-family: PingFang SC; font-weight: bold; line-height: 17px; color: rgba(255, 255, 255, 0.85); border-radius: 4px;">
                                <span style="position: absolute; top: -1px; right: 4px; color: #0088FF; font-weight: bold;" @click="close($event)">×</span>
                                    ${camera.cameraName}
                              </div>`,
                    methods: {
                        close(dom) {
                            dom.target.parentNode.remove()
                        }
                    }
                })
                let component = new MyComponent().$mount()
                this.infoWindow.setContent(component.$el)
                this.infoWindow.open(map, position) //信息窗体打开
            },
            moveToCamera (camera) { // 视角移动到相机
                let point = ampUtil.splitlngLat(camera.longAndLati)
                map.panTo(point, 1)
            },
            getCameraData (id) { // 获取相机数据
                getAssignedCamera(id).then(res => {
                    this.createCameraMarker(res.data)
                })
            },
            getWeather () { // 查询天气数据
                //加载天气查询插件
                AMap.plugin('AMap.Weather', () => {
                    //创建天气查询实例
                    let weather = new AMap.Weather()
                    this.cityData.forEach(city => {
                        //执行实时天气信息查询
                        weather.getLive(city.name, (err, data) => {
                            // console.log(err)
                            console.log(data)
                            // 创建天气图标Marker
                            let weatherMarker = new AMap.Marker({
                                position: city.position,
                                offset: new AMap.Pixel(0, 0)
                            })
                            map.add(weatherMarker)
                        })
                    })
                })
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.mapCreate()
                this.createRoadSignMarker() // 路标指示牌
                this.createInterestPointData()
                // this.getWeather()
            })
        }
    }
</script>

<style lang="less" scoped>

    @import url("https://a.amap.com/jsapi_demos/static/demo-center/css/demo-center.css");

    .amap-container /deep/ .amap-logo {
        right: 0 !important;
        left: auto !important;
        display: none !important;
    }

    .amap-container /deep/ .amap-copyright {
        right: 70px !important;
        left: auto !important;
        opacity: 0 !important;
    }


    .map-wrapper /deep/ .interest-point-dialog {
        background: #FFFFFF;
        z-index: 999;
        height: 36px;
        line-height: 36px;
        border-radius: 8px;
        border: 1px solid;

        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);

        .image {
            height: 34px;
            width: 36px;
            border-radius: 6px 0px 0px 6px;
            position: relative;
            text-align: center;
            position: absolute;

            img {
                height: 24px;
                width: 24px;
                margin-bottom: 2px;
            }
        }

        .text {
            position: relative;
            padding-left: 48px;
            padding-right: 12px;

            span {
                font-weight: 800;
            }
        }

        &:after {
            content: "";
            position: absolute;
            left: 12px;
            bottom: -12px;
            border-right: 7px solid transparent;
            border-left: 7px solid transparent;
        }
    }

    .map-wrapper /deep/ .amap-marker {
        width: max-content;
    }


    /*道路易结冰路段*/
    .map-wrapper /deep/ .dialog-iceProneSection {
        --baseColor: #0EBFF7;
        border-color: var(--baseColor) ;
        .image {
            background: var(--baseColor);
            img {
                content: url("../../assets/images/importantRoad/roadType/icon_zdld_jb.png");
            }
        }
        &:after {
            border-top: 12px solid var(--baseColor);
        }
    }
    .map-wrapper /deep/ .dialog-iceProneSection-active {
        --flashColor: #ABEBFF;
        animation: flashing-iceProneSection 1s infinite;
        background: var(--flashColor);
        @keyframes flashing-iceProneSection {
            0% {
                background: var(--flashColor);
            }
            50% {
                background: #FFFFFF;
            }
            100% {
                background: var(--flashColor);
            }
        }
    }


    /*长大下坡路段*/
    .map-wrapper /deep/ .dialog-longDownhillSection {
        --baseColor: #2FBC34;
        border-color:var(--baseColor) ;
        .image {
            background: var(--baseColor);
            img {
                content: url("../../assets/images/importantRoad/roadType/icon_zdld_xp.png");
            }
        }
        &:after {
            border-top: 12px solid var(--baseColor);
        }
    }
    .map-wrapper /deep/ .dialog-longDownhillSection-active {
        --flashColor: #A8FFAB;
        animation: flashing-longDownhillSection 1s infinite;
        background: var(--flashColor);
        @keyframes flashing-longDownhillSection {
            0% {
                background: var(--flashColor);
            }
            50% {
                background: #FFFFFF;
            }
            100% {
                background: var(--flashColor);
            }
        }
    }


    /*易拥堵路段*/
    .map-wrapper /deep/ .dialog-congestionSection {
        --baseColor: #E38B2A;
        border-color:var(--baseColor) ;
        .image {
            background: var(--baseColor);
            img {
                content: url("../../assets/images/importantRoad/roadType/icon_zdld_yd.png");
            }
        }
        &:after {
            border-top: 12px solid var(--baseColor);
        }
    }
    .map-wrapper /deep/ .dialog-congestionSection-active {
        --flashColor: #FFE7CC;
        animation: flashing-congestionSection 1s infinite;
        background: var(--flashColor);
        @keyframes flashing-congestionSection {
            0% {
                background: var(--flashColor);
            }
            50% {
                background: #FFFFFF;
            }
            100% {
                background: var(--flashColor);
            }
        }
    }


    /*易发生事故路段*/
    .map-wrapper /deep/ .dialog-trafficAccidentSection {
        --baseColor: #E42A8E;
        border-color:var(--baseColor) ;
        .image {
            background: var(--baseColor);
            img {
                content: url("../../assets/images/importantRoad/roadType/icon_zdld_sg.png");
            }
        }
        &:after {
            border-top: 12px solid var(--baseColor);
        }
    }
    .map-wrapper /deep/ .dialog-trafficAccidentSection-active {
        --flashColor: #FFDBEF;
        animation: flashing-trafficAccidentSection 1s infinite;
        background: var(--flashColor);
        @keyframes flashing-trafficAccidentSection {
            0% {
                background: var(--flashColor);
            }
            50% {
                background: #FFFFFF;
            }
            100% {
                background: var(--flashColor);
            }
        }
    }


    /*易出现雾霾/大雾路段*/
    .map-wrapper /deep/ .dialog-lowVisibilitySection {
        --baseColor: #969697;
        border-color:var(--baseColor) ;
        .image {
            background: var(--baseColor);
            img {
                content: url("../../assets/images/importantRoad/roadType/icon_zdld_wm.png");
            }
        }
        &:after {
            border-top: 12px solid var(--baseColor);
        }
    }
    .map-wrapper /deep/ .dialog-lowVisibilitySection-active {
        --flashColor: #D9D9D9;
        animation: flashing-lowVisibilitySection 1s infinite;
        background: var(--flashColor);
        @keyframes flashing-lowVisibilitySection {
            0% {
                background: var(--flashColor);
            }
            50% {
                background: #FFFFFF;
            }
            100% {
                background: var(--flashColor);
            }
        }
    }


    /*易山体滑坡路段*/
    .map-wrapper /deep/ .dialog-mountainSlideSection {
        --baseColor: #ECD727;
        border-color:var(--baseColor) ;
        .image {
            background: var(--baseColor);
            img {
                content: url("../../assets/images/importantRoad/roadType/icon_zdld_hp.png");
            }
        }
        &:after {
            border-top: 12px solid var(--baseColor);
        }
    }
    .map-wrapper /deep/ .dialog-mountainSlideSection-active {
        --flashColor: #FFF8C2;
        animation: flashing-mountainSlideSection 1s infinite;
        background: var(--flashColor);
        @keyframes flashing-mountainSlideSection {
            0% {
                background: var(--flashColor);
            }
            50% {
                background: #FFFFFF;
            }
            100% {
                background: var(--flashColor);
            }
        }
    }


    /*特大桥路段*/
    .map-wrapper /deep/ .dialog-bigBridgeSection {
        --baseColor: #8F29E8;
        border-color:var(--baseColor) ;
        .image {
            background: var(--baseColor);
            img {
                content: url("../../assets/images/importantRoad/roadType/icon_zdld_dq.png");
            }
        }
        &:after {
            border-top: 12px solid var(--baseColor);
        }
    }
    .map-wrapper /deep/ .dialog-bigBridgeSection-active {
        --flashColor: #E5C7FF;
        animation: flashing-bigBridgeSection 1s infinite;
        background: var(--flashColor);
        @keyframes flashing-bigBridgeSection {
            0% {
                background: var(--flashColor);
            }
            50% {
                background: #FFFFFF;
            }
            100% {
                background: var(--flashColor);
            }
        }
    }

</style>
