<!--兴趣点占比图表-->
<template>
    <div>
        <panel style="background: rgba(21, 23, 97, 0.85)">

            <div class="panel-title">
                兴趣点占比分析
            </div>
            <div class="chart-wrapper">
                <div id="interest-point-chart-container" style="height: 258px; width: 300px; margin: 0 auto;"></div>
            </div>
        </panel>
    </div>
</template>

<script>

    import Panel from '../../components/common/Panel'
    import {IMPORTANT_ROAD_SECTION} from '../../core/enums'
    import ECharts from 'vue-echarts'
    import 'echarts/index'
    import echarts from 'echarts/lib/echarts'

    let chart

    // 饼图文字颜色
    const richStyle = {
        style1: {
            color: '#00DCFF',
            padding: [6, 0, 0, 0]
        },
        style2: {
            color: '#00FF80',
            padding: [6, 0, 0, 0]
        },
        style3: {
            color: '#FFAA48',
            padding: [6, 0, 0, 0]
        },
        style4: {
            color: '#FF639D',
            padding: [6, 0, 0, 0]
        },
        style5: {
            color: '#B9B9B9',
            padding: [6, 0, 0, 0]
        },
        style6: {
            color: '#F3DF28',
            padding: [6, 0, 0, 0]
        },
        style7: {
            color: '#C436FF',
            padding: [6, 0, 0, 0]
        }
    }


    export default {
        name: "InterestPointChart",
        components: {
            Panel,
            'v-chart': ECharts
        },
        data() {
            return {
                eventData: null,
                polar: {
                    // tooltip: {
                    //   trigger: 'item'
                    // },
                    title: {
                        show: true,
                        text: '',
                        left: 'center',
                        top: '34%',
                        textStyle: {
                            rich: {
                                title: {
                                    color: 'rgba(255, 255, 255, 0.85)',
                                    fontSize: 20,
                                    fontWeight: 800,
                                    fontFamily: 'PingFang SC'
                                },
                                count: {
                                    color: '#FFFFFF',
                                    fontSize: 40,
                                    fontWeight: 400,
                                    fontFamily: 'lcd',
                                    padding: [14, 0, 0, 0],
                                }
                            }
                        }
                    },
                    series: [
                        {
                            name: '事件数量',
                            type: 'pie',
                            radius: ['65%', '80%'],
                            label: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    color: 'rgba(255, 255, 255, 0.85)',
                                    fontSize: 20,
                                    fontWeight: 800,
                                    fontFamily: 'PingFang SC',
                                    formatter: (params) => {
                                        return `{style${params.dataIndex + 1}|${params.name}}` + '\n' + `{styleValue|${params.value}}` + '\n' + `{stylePercent|${params.percent}%}`
                                    },
                                    rich: {
                                        ...richStyle,
                                        styleValue: {
                                            color: 'rgba(255, 255, 255, 0.85)',
                                            fontSize: 28,
                                            fontWeight: 400,
                                            fontFamily: 'lcd',
                                            padding: [10, 0, 0, 0]
                                        },
                                        stylePercent: {
                                            color: 'rgba(255, 255, 255, 0.85)',
                                            fontSize: 22,
                                            fontWeight: 400,
                                            fontFamily: 'lcd',
                                            padding: [6, 0, 0, 0]
                                        }
                                    }
                                }
                            },
                            // data: [
                            //     {value: 1048, name: '道路易结冰路段'},
                            //     {value: 735, name: '长达下坡路段'},
                            //     {value: 580, name: '易拥堵路段'},
                            //     {value: 484, name: '易发生事故路段'},
                            //     {value: 300, name: '易出现雾霾/大雾路段'},
                            //     {value: 300, name: '易山体滑坡路段'},
                            //     {value: 300, name: '特大桥路段'}
                            // ],
                            data: [],
                            itemStyle: {
                                emphasis: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                },
                                normal: {
                                    color: (params) => {
                                        //自定义颜色
                                        let colorList = ['#00DCFF', '#00FF80', '#FFAA48', '#FF639D', '#B9B9B9', '#F3DF28', '#C436FF']
                                        return colorList[params.dataIndex]
                                    }
                                }
                            }
                        }
                    ]
                }
            }
        },
        methods: {
            refreshData(roadSectionCountData) {
                let chartData = []
                this.polar.title.text = `{title|总兴趣点数量} \n {count|${roadSectionCountData.poiTotal}}`
                for (let key in IMPORTANT_ROAD_SECTION) {
                    let data = {}
                    data.name = IMPORTANT_ROAD_SECTION[key].name
                    data.value = roadSectionCountData[key]
                    chartData.push(data)
                }
                this.polar.series[0].data = chartData
                chart.clear()
                chart.setOption(this.polar)
            },
            showChartTitle() {
                this.polar.title.show = true
                chart.setOption(this.polar)
            },
            hideChartTitle() {
                this.polar.title.show = false
                chart.setOption(this.polar)
            },
            handleEventOver(index) {
                chart.dispatchAction({
                    type: 'highlight',
                    dataIndex: index
                })
                this.hideChartTitle()
            },
            handleEventLeave(index) {
                chart.dispatchAction({
                    type: 'downplay',
                    dataIndex: index
                })
            }
        },
        mounted() {
            this.$nextTick(() => {
                chart = echarts.init(document.getElementById('interest-point-chart-container'))
                chart.setOption(this.polar)
                chart.on('mouseover', () => {
                    this.hideChartTitle()
                })
                chart.on('mouseout', () => {
                    this.showChartTitle()
                })
            })
        }
    }
</script>

<style lang="less" scoped>

    .panel-title {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 800;
        color: #0088FF;
        margin-left: 16px;
        padding-top: 16px;
    }

    .chart-wrapper {
        position: absolute;
        top: 30px;
        right: 0px;
        left: 0px;
    }

</style>
